import React, { useState, useEffect, useRef } from "react";
import { Button } from 'antd';
import axios from 'axios';
import { host } from '../config.js';
import gif from './giphy.gif'
import { useNavigate } from 'react-router-dom';

const Payments = () => {

    const inputRef = useRef(null);
    const navigate = useNavigate();

    let [code, setCode] = useState([]);
    let [message, setMessage] = useState("");
    let [loading, setLoading] = useState(false);
    let [isMassageOnScreen, setMessageOnScreen] = useState(false);
    let [visitData, setVisitDate] = useState({});
    let [error, setError] = useState(false);

    const addVisit = async (datCode) => {



        setLoading(true);

        let data = await axios.post(host + "/new_visit", {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
            code: datCode
        });

        setLoading(false);
        if (data.data.error) {
            setMessageOnScreen(true);
            setError(true);
            setMessage(data.data.message);
            setCode("");
        } else {
            setMessageOnScreen(true);
            setError(false);
            setVisitDate(data.data.message);
            setCode("");
        }

        setTimeout(() => {
            setLoading(false);
            setError(false);
            setMessage("");
            setMessageOnScreen(false);
        }, 5000);
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 200);

        return () => {
          clearInterval(intervalId);
        };
      }, []);

    const formatDate = (str) => {
        let date = new Date(str);

        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        let hours = date.getHours();
        if (hours < 10) hours = "0" + hours;

        let minutes = date.getMinutes();
        if (minutes < 10) minutes = "0" + minutes;

        return day + "/" + month + "/" + year;
    }
    return (
        <div style={{
            background: "#030303",
            width: "100%",
            height: "100vh"
        }}>

            <input id="input" ref={inputRef} value={code} onChange={(e) => {
                setCode(e.target.value);
                console.log(e.target.value.length);
                console.log(e.target.value);
                if (e.target.value.length === 6) {
                    setTimeout(() => {
                        if (isMassageOnScreen) {
                            setCode("");
                        } else {
                            addVisit(e.target.value);
                        }

                    }, 100)

                }
            }} />

            {loading ? "" : null}

            {

                isMassageOnScreen

                    ?

                    error ?
                        <div style={{ width: "100%", textAlign: "center", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
                            <h1 style={{ color: "red", fontSize: "60px", }}>
                                {message}
                            </h1>
                        </div>
                        :
                        <div style={{
                            width: "100%",
                            textAlign: "center",
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%,-50%)"
                        }}>
                            <h1 style={{color: "white", fontSize: "90px",}}>
                                Բարի գալուստ

                            </h1>
                            <h1 style={{fontSize: "60px", color: "orange"}}>{visitData.name}</h1>
                            <h2 style={{color: "white", fontSize: "60px",}}>
                                Այցելոթյունների քանակը : <span
                                style={{color: "orange", fontSize: "60px"}}> {visitData.visits}</span>
                            </h2>
                            <h2 style={{color: "white", fontSize: "60px",}}>
                                Այցելոթյուն N: <span
                                style={{color: "orange", fontSize: "60px"}}> {visitData.current} </span>
                            </h2>
                            <h2 style={{color: "white", fontSize: "60px",}}>
                                Այցելությունները գործում են մինչև: <span
                                style={{color: "yellow", fontSize: "60px"}}> {formatDate(visitData.exp)} </span>
                            </h2>
                            <h2 style={{color: "white", fontSize: "60px",}}>
                                Փաթեթի տարբերակը: <span
                                style={{color: "yellow", fontSize: "60px"}}> {visitData.with_trainer} </span>
                            </h2>
                        </div>


                    :

                    <div style={{
                        textAlign: "center",
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%,-50%)"
                    }}>
                        <Button onClick={() => navigate(`/data/users`)}>Գլխավոր էջ</Button>
                        <h1 style={{ color: "white", fontSize: "60px", }}>
                            Սկանավորեք ձեր քարտը
                        </h1>
                        <img src={gif} />
                    </div>

            }

        </div >
    )

}

export default Payments;
